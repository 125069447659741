import LogoColor from "@/public/images/logoColor";

export default function Footer() {
  return (
    <div className="max-w-screen-xl px-4 mx-auto mb-12">
      <hr className="border-t-2 mb-6 border-primary" />
      <div className="flex justify-between items-center mb-12">
        <LogoColor />
        <div className="text-xs font-thin">Rooph, Inc. All Rights Reserved.</div>
      </div>
    </div>
  );
}
